<template>
  <div class="dhme-data-validation-container">

    <portal to="ant-toolbar">
      <module-navigation-bar title="Data validation">
        <template slot="module-nav-logo">
          <img
            alt=""
            height="40px"
            src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
          />
        </template>
      </module-navigation-bar>
    </portal>
    <div
      v-if="dhmeDataValidationDataStatus === 'success'"
      class="d-flex flex-grow-1 overflow-hidden"
    >
      <div
        ref="table-container"
        :class="{ 'flex-1': !selectedValidation }"
        :style="{ width: selectedValidation ? `${sidebarSize}px` : '100%' }"
        class="overflow-y-auto flex-scroll-height d-flex overflow-x-hidden"
      >
        <v-list class="bg-transparent pr-5 flex-1" shaped>
          <v-subheader
            class="text-uppercase font-weight-bold"
            style="font-size: 16px"
          >
            Validation Checks
          </v-subheader>
          <v-list-item-group v-model="dataValidationTab" color="primary">
            <v-list-item v-for="item in validations" :key="item.label">
              <v-list-item-icon>
                <v-icon
                  v-if="tableLoading && selectedValidation === item"
                  class="mdi-spin"
                  color="primary"
                >
                  mdi-loading
                </v-icon>
                <v-icon v-else :color="item.validation.status">
                  {{
                    item.validation.status
                      ? item.validation.status === 'warning'
                        ? 'mdi-alert-circle-outline'
                        : 'mdi-check-circle-outline'
                      : 'mdi-circle'
                  }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Validate {{ item.label }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <div v-if="selectedValidation" class="flex-grow-1 overflow-x-auto">
          <dynamic-data-table
            :is-loading="tableLoading"
            :table-headers="tableHeaders"
            :table-height="tableHeight"
            :table-records="tableRecords"
            :table-title="selectedValidation.label"
            class="ant-glass-background radius-0 full-height ant-border-left ant-border-right"
          >
            <template #table-actions>
              <v-alert
                v-if="tableRecords.length > 0"
                class="mt-2 mb-2"
                dense
                type="warning"
              >
                Missing {{ selectedValidation.column }} for
                {{ tableRecords.length }} records
              </v-alert>
              <v-tooltip v-if="selectedValidation.column" bottom>
                <template #activator="{ on, attrs }">
                  <v-btn
                    :disabled="tableLoading"
                    color="primary"
                    icon
                    v-bind="attrs"
                    @click="displayObjectsWithIncorrectColumnValue"
                    v-on="on"
                  >
                    <v-icon> mdi-select-inverse </v-icon>
                  </v-btn>
                </template>
                <span
                  >Display objects missing {{ selectedValidation.column }} in
                  viewer</span
                >
              </v-tooltip>
            </template>
            <template #item.element_id="{ value, rowId, item }">
              <v-tooltip bottom color="error">
                <template #activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-icon color="error"> mdi-alert </v-icon>
                    {{ value }}
                  </div>
                </template>
                <span>{{
                  `${value} element id found in multiple module_id's`
                }}</span>
              </v-tooltip>
            </template>
            <template #item.modules="{ value, rowId, item }">
              <div class="d-flex flex-column">
                <v-chip
                  v-for="(module, index) in value"
                  :key="index"
                  class="my-1"
                  label
                  small
                  @click="searchElementInModel(item.element_id, module)"
                >
                  {{ module }}
                </v-chip>
              </div>
            </template>
          </dynamic-data-table>
        </div>
        <div
          class="sidebar-resize ant-border-right"
          @mousedown="startResizeSidebar"
        >
          <v-icon> mdi-drag-vertical-variant </v-icon>
        </div>
      </div>
      <div class="data-validation-viewer">
        <ant-toolbar-container ant-toolbar-prefix="dhme-dv" />
        <forge-viewer
          ref="forge-viewer"
          ant-toolbar-prefix="dhme-dv"
          :ant-toolbar-options="{
            viewType: {
              display: true,
              enabled: false,
            },
            performanceMode: {
              display: true,
              enabled: true,
            },
            models: {
              display: false,
              enabled: false,
            },
            antTable: {
              display: false,
              enabled: false,
            },
            ghosting: {
              display: true,
              enabled: false,
            },
            modelTree: {
              display: false,
              enabled: false,
            },
            clearIsolation: {
              display: true,
              enabled: false,
            },
            objectProperties: {
              display: false,
              enabled: true,
            },
            sidebar: {
              display: false,
              enabled: true,
            },
          }"
          :client="dhmeDataValidationClient"
          :extension-options="[]"
          :extensions="[]"
          :models="dhmeDataValidationModels"
          @fetchedObjectsByModels="setModelObjects"
        />
      </div>
    </div>
    <div v-else class="d-flex justify-center align-center full-height">
      <ant-loading />
    </div>
  </div>
</template>

<script>
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar';
import { DHME_DATA_VALIDATION } from '@/modules/modules';
import { mapGetters } from 'vuex';
import DynamicDataTable from '@/components/DynamicDataTable';
import ForgeViewer from '@/components/Modules/Forge/ForgeViewer';
import AntLoading from '@/components/AntLoading';
import { getObjectsWithMappingLevel } from '@/components/Modules/Daiwa-House-Modular-Europe/utils/DHME+utils';
import { executeCustomModuleCall } from '@/services/api/module.api';
import { Portal } from 'portal-vue';
import AntToolbarContainer from '@/components/AntToolbarContainer';

export default {
  name: 'DHMEDataValidation',
  components: {
    Portal,
    AntToolbarContainer,
    AntLoading,
    ForgeViewer,
    DynamicDataTable,
    ModuleNavigationBar,
  },
  data: () => {
    return {
      dataValidationTab: undefined,
      validations: [
        {
          label: 'Module ID',
          column: 'module_id',
          modelColumn: 'EquipmentNummer',
          customApiCall: {
            body: {
              column: 'module_id',
            },
            apiFunction: 'objectValidationEmptyColumn',
          },
          validation: {
            status: '',
            count: 0,
          },
        },
        {
          label: 'Module Type',
          column: 'module_type',
          modelColumn: 'ModuleType',
          customApiCall: {
            body: {
              column: 'module_type',
            },
            apiFunction: 'objectValidationEmptyColumn',
          },
          validation: {
            status: '',
            count: 0,
          },
        },
        {
          label: 'Element ID',
          column: 'element_id',
          modelColumn: 'ElementId',
          customApiCall: {
            body: {
              column: 'element_id',
            },
            apiFunction: 'objectValidationEmptyColumn',
          },
          validation: {
            status: '',
            count: 0,
          },
        },
        {
          label: 'Element Type',
          column: 'element_type',
          modelColumn: 'ElementCode',
          customApiCall: {
            body: {
              column: 'element_type',
            },
            apiFunction: 'objectValidationEmptyColumn',
          },
          validation: {
            status: '',
            count: 0,
          },
        },
        {
          label: 'Object ID',
          column: 'object_id',
          modelColumn: 'ObjectId',
          customApiCall: {
            body: {
              column: 'object_id',
            },
            apiFunction: 'objectValidationEmptyColumn',
          },
          validation: {
            status: '',
            count: 0,
          },
        },
        {
          label: 'Object Type',
          column: 'object_type',
          modelColumn: 'ObjectType',
          customApiCall: {
            body: {
              column: 'object_type',
            },
            apiFunction: 'objectValidationEmptyColumn',
          },
          validation: {
            status: '',
            count: 0,
          },
        },
        {
          label: 'Element Duplicates',
          column: null,
          customApiCall: {
            body: {},
            apiFunction: 'objectValidationFindElementDuplicates',
          },
          validation: {
            status: '',
            count: 0,
          },
        },
      ],
      propertiesOfModels: [],
      missingColumnTableHeaders: [
        {
          text: 'module id',
          value: 'module_id',
        },
        {
          text: 'module type',
          value: 'module_type',
        },
        {
          text: 'element id',
          value: 'element_id',
        },
        {
          text: 'element type',
          value: 'element_type',
        },
        {
          text: 'object id',
          value: 'object_id',
        },
        {
          text: 'object type',
          value: 'object_type',
        },
      ],
      duplicateElementHeaders: [
        {
          text: 'element id',
          value: 'element_id',
          hasSlot: true,
        },
        {
          text: 'modules',
          value: 'modules',
          hasSlot: true,
        },
      ],
      sidebarSize: 1200,
      tableHeight: '100%',
      tableLoading: false,
      tableRecords: [],
    };
  },
  computed: {
    ...mapGetters([
      'project',
      'dhmeDataValidationDataStatus',
      'dhmeDataValidationObjects',
      'dhmeDataValidationObjectsTable',
      'dhmeDataValidationClient',
      'dhmeDataValidationModels',
      'dhmeDataValidationModelMapping',
    ]),

    selectedValidation() {
      return this.validations[this.dataValidationTab];
    },
    tableHeaders() {
      if (this.selectedValidation.column) {
        return this.missingColumnTableHeaders;
      } else {
        return this.duplicateElementHeaders;
      }
    },
    moduleId() {
      return this.project.modules.find(
        (module) => module.route === DHME_DATA_VALIDATION
      ).id;
    },
  },
  watch: {
    dataValidationTab: {
      handler(value) {
        if (value !== undefined) {
          if (this.$refs['table-container']) {
            this.tableHeight = `${
              this.$refs['table-container'].offsetHeight - 64 - 59 - 16
            }px`;
          }
          this.executeValidation();
        }
      },
    },
  },
  mounted() {
    this.$store.dispatch('fetchDhmeDataValidation', {
      projectId: this.project.id,
      moduleId: this.moduleId,
      sessionId: this.$route.params.sessionId ?? null,
    });
  },
  methods: {
    setModelObjects(objects) {
      this.modelProperties = objects.flatMap((item) => item.properties);
    },
    searchElementInModel(element, module) {
      const moduleIdMappingLevels =
        this.dhmeDataValidationModelMapping.module_id.split('.');
      const elementIdMappingLevels =
        this.dhmeDataValidationModelMapping.element_id.split('.');
      let moduleIdObjects = getObjectsWithMappingLevel(
        this.modelProperties,
        moduleIdMappingLevels
      ).filter(
        (object) =>
          moduleIdMappingLevels.reduce((o, i) => o[i], object.properties) ===
          module
      );
      let objects = getObjectsWithMappingLevel(
        moduleIdObjects,
        elementIdMappingLevels
      ).filter(
        (object) =>
          elementIdMappingLevels.reduce((o, i) => o[i], object.properties) ===
          element
      );

      if (objects.length > 0) {
        this.$refs['forge-viewer'].viewerService.Viewer3D.isolate(
          objects.map((object) => object.objectid)
        );
      } else {
        this.$refs['forge-viewer'].viewerService.Viewer3D.hideAll();
      }
    },

    displayObjectsWithIncorrectColumnValue() {
      const mappingLevels =
        this.dhmeDataValidationModelMapping[
          this.selectedValidation.column
        ].split('.');
      let objects = getObjectsWithMappingLevel(
        this.modelProperties,
        mappingLevels
      ).filter(
        (object) => !mappingLevels.reduce((o, i) => o[i], object.properties)
      );

      if (objects.length > 0) {
        this.$refs['forge-viewer'].viewerService.Viewer3D.isolate(
          objects.map((object) => object.objectid)
        );
      } else {
        this.$refs['forge-viewer'].viewerService.Viewer3D.hideAll();
      }
    },
    startResizeSidebar(e) {
      document.addEventListener('mousemove', this.dragSidebar);
      document.addEventListener('mouseup', this.stopResizeSidebar);
    },
    dragSidebar(e) {
      this.sidebarSize = e.clientX + 12;
      this.$refs['forge-viewer'].viewerService.resizeView();
    },
    stopResizeSidebar(e) {
      document.removeEventListener('mousemove', this.dragSidebar);
      document.removeEventListener('mouseup', this.stopResizeSidebar);

      this.$refs['forge-viewer'].viewerService.resizeView();
    },
    async executeValidation() {
      this.tableLoading = true;
      this.tableRecords = [];
      // create custom body
      let body = this.selectedValidation.customApiCall.body;
      body.project = {
        id: this.project.id,
      };
      body.table = {
        id: this.dhmeDataValidationObjectsTable,
      };

      console.log(body);

      // execute custom api call
      this.tableRecords = await executeCustomModuleCall(
        this.project.id,
        this.moduleId,
        this.selectedValidation.customApiCall.apiFunction,
        body
      );
      if (this.tableRecords.length > 0) {
        this.selectedValidation.validation.status = 'warning';
        this.selectedValidation.validation.count = this.tableRecords.length;
      } else {
        this.selectedValidation.validation.status = 'success';
        this.selectedValidation.validation.count = 0;
      }
      this.tableLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.dhme-data-validation-container {
  display: flex;
  height: 100%;
  flex-direction: column;

  .data-validation-content {
    flex: 1;
    overflow-y: scroll;
    min-height: 0;
  }

  .data-validation-viewer {
    flex: 1;
    position: relative;
  }
}
</style>
